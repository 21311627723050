import styled from 'styled-components';


export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
`;

export const Content = styled.div`
  flex: 1;
  max-width: 1080px;
  display: flex;
  flex-direction: row;
  justify-content: space-beween;
`;