import styled from 'styled-components';
import Img from 'gatsby-image'
import { PADDING } from '../../style';

export const Wrapper = styled.div`
  position: relative;
  height: calc(100vh - 60px);
  overflow: hidden;
`;

export const Content = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: ${PADDING}px;
`;

export const Image = styled(Img)`
  width: 100%;
  height: 100%;
  filter: blur(${props => props.blur}px);
  transform: scale(1.1);
  object-position: 50% 35%;
`