import React from 'react'
import { Helmet } from 'react-helmet'
import { createGlobalStyle } from "styled-components"
import reset from 'styled-reset'
import { BreakpointsProvider } from 'react-with-breakpoints';

import Footer from '../Footer'
import Navbar from '../Navbar'

import { useSiteMeta} from '../../hooks'
import { PALE_GREEN, BLACK, GREEN } from '../../style/colors'

import { Wrapper } from './Layout.style';

const GlobalStyle = createGlobalStyle`
  ${reset}
  * {
    box-sizing: border-box;
  }
  body {
    font-family: 'Raleway', sans-serif;
    background: ${PALE_GREEN};
    color: #222;
    font-size: 16px;
    line-height: 160%;
    -webkit-font-smoothing: antialiased;
  }
  strong {
    font-weight: bold;
  }
  a {
    text-decoration: none;
    color: ${GREEN};
  }
  h1, h2, h3, h4, h5 {
    font-weight: normal;
    color: ${BLACK};
    font-family: 'Radiant Beauty', cursive;
    line-height: 110%;
  }
  h1 {
    font-size: 9em;
  }
  h2 {
    font-size: 7em;
  }
  h3 {
    font-size: 5em;
  }
  h4 {
    font-size: 3em;
  }
  h5 {
    font-size: 2em;
  }
  @media screen and (max-width: 600px) {
    h1 {
    font-size: 7em;
    }
    h2 {
      font-size: 5em;
    }
    h3 {
      font-size: 4em;
    }
  }
`

const TemplateWrapper = ({ children, links }) => {
  const { title, description } = useSiteMeta()
  return (
    <BreakpointsProvider>
      <GlobalStyle theme="purple" />
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta property="description" content={description} />
        <meta property="theme-color" content="#fff" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta property="msapplication-TileColor" content="#ffffff" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://littleberries.co.uk/" />
        <meta property="fb:app_id" content="LittleBerriesReflexology" />
        <meta property="fb:admins" content="danielle.humphrey.14" />
        <meta property="og:image" content="https://littleberries.co.uk/static/little-berries-no-text-edea9f1086e79f7e6accf54461b09908.png" />
        <meta property="og:image:width" content="338" />
        <meta property="og:image:height" content="309" />
        <link href="https://fonts.googleapis.com/css?family=Raleway:400,700&display=swap" rel="stylesheet" />
      </Helmet>
      <Navbar links={links} />
      <Wrapper>{children}</Wrapper>
      <Footer />
    </BreakpointsProvider>
  )
}

export default TemplateWrapper
