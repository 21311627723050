import React, { useState } from 'react'
import { ShowAt, HideAt } from 'react-with-breakpoints';

import HamburgerIcon from '../../svg/hamburger-icon.svg';
import { useSiteMeta } from '../../hooks';
import { Nav, GatsbyLink, Link, AlignLeft, AlignRight, HamburgerButton, MobileNav } from './Navbar.style'
import logo from '../../img/little-berries-no-text.png'
import WidthWrapper from '../WidthWrapper';
import NavLinks from './NavLinks';


export default function Navbar({ links }) {
  const [isOpen, setIsOpen] = useState(false)
  const { phone, email, nav } = useSiteMeta();
  return (
    <Nav role="navigation" aria-label="main-navigation">
      <WidthWrapper>
        <AlignLeft>
          <GatsbyLink to="/" title="Home">
            <img src={logo} alt="Little Berries Logo" style={{ width: '60px' }} />
          </GatsbyLink>
          <ShowAt breakpoint="largeAndAbove">
            <NavLinks links={nav.links} />
          </ShowAt>
        </AlignLeft>
        <AlignRight>
          <ShowAt breakpoint="largeAndBelow">
            <HamburgerButton onClick={() => setIsOpen(!isOpen)}>
              <HamburgerIcon />
            </HamburgerButton>
          </ShowAt>
          <HideAt breakpoint="largeAndBelow">
            <Link href={`tel:${phone}`}>{phone}</Link>
            <Link href={`mailto:${email}`}>{email}</Link>
          </HideAt>
        </AlignRight>
      </WidthWrapper>
      {isOpen && (
        <WidthWrapper>
          <MobileNav>
            <ShowAt breakpoint="mediumAndBelow">
              <NavLinks links={nav.links} />
            </ShowAt>
            <Link href={`tel:${phone}`}>{phone}</Link>
            <Link href={`mailto:${email}`}>{email}</Link>
          </MobileNav>
        </WidthWrapper>
      )}
    </Nav>
  )
}
