import styled from 'styled-components';
import { Link as GatsbyLinkOriginal } from 'gatsby'
import { PADDING } from '../../style';
import { GREEN, PALE_GREEN, WHITE, ORANGE } from '../../style/colors';

export const Nav = styled.nav`
  min-height: 60px;
  width: 100%;
  background: ${WHITE};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 ${PADDING}px;
  position: fixed;
  z-index: 1;
`;

export const AlignRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const AlignLeft = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const linkStyles = `
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${GREEN};
  font-style: none;
  white-space: nowrap;

  &:hover {
    color: ${PALE_GREEN};
  }

  &[aria-current="page"] {
    color: ${ORANGE};
  }
`

export const GatsbyLink = styled(GatsbyLinkOriginal)`
  ${linkStyles}
  font-size: 14px;
  margin-right: ${PADDING * 2}px;
  font-weight: bold;
`

export const Link = styled.a`
  ${linkStyles}
  font-size: 14px;
  margin-left: ${PADDING * 2}px;
  &:first-of-type {
    margin-left: 0;
  }
`;

export const HamburgerButton = styled.button`
  border: none;
  background: transparent;
  color: ${GREEN};
`;

export const MobileNav = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  ${GatsbyLink}, ${Link} {
    margin: 10px 0;
  }
`;