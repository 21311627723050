import React from 'react'
import PropTypes from 'prop-types'
import showdown from 'showdown'
import { Content } from '../style/home.style'

const converter = new showdown.Converter()

const MarkdownContent = ({ content }) => (
  <Content dangerouslySetInnerHTML={{ __html: converter.makeHtml(content) }} />
)

MarkdownContent.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
}


export default MarkdownContent