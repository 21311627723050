import React from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid';

import { useSiteMeta } from '../../hooks';
import { Wrapper } from './Footer.style';
import WidthWrapper from '../WidthWrapper';


export default function Footer() {
  const { title, phone, email, address, facebook, instagram } = useSiteMeta();

  return (
    <WidthWrapper>
      <Wrapper>
        <Grid>
          <Row>
            <Col xs={12} sm={6} md={4}>
              <h5>{title}</h5>
              {address.map(line => <div>{line}</div>)}
            </Col>
            <Col xs={12} sm={6} md={4}>
              <div>t: <a href={`tel:${phone}`}>{phone}</a></div>
              <div>e: <a href={`mailto:${email}`}>{email}</a></div>
              <div>fb: <a href={facebook}>@LittleBerriesTherapies</a></div>
              <div>ig: <a href={instagram}>@LittleBerriesTherapies</a></div>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <div>© All rights reserved 2019</div>
              <div>Website by <a href="https://bristolgrade.co.uk">Bristol Grade Ltd</a></div>
            </Col>
          </Row>
        </Grid>
      </Wrapper>
      
    </WidthWrapper>
  )
}
