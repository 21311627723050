import React from 'react'

import { Wrapper, Content } from './WidthWrapper.style';

export default function WidthWrapper({ children }) {
  return (
    <Wrapper>
      <Content>
        {children}
      </Content>
    </Wrapper>
    
  )
}
