import React, { Fragment } from 'react'
import { GatsbyLink } from './Navbar.style'


export default function NavLinks({ links }) {
  return (
    <Fragment>
      {links.map(link => (
        <GatsbyLink key={link.path} to={link.path}>
          {link.label}
        </GatsbyLink>
      ))}
    </Fragment>
  )
}