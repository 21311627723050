import React from 'react'
import { Helmet } from 'react-helmet'
import { createGlobalStyle } from "styled-components"
import reset from 'styled-reset'
import { BreakpointsProvider } from 'react-with-breakpoints';

import { PALE_GREEN, BLACK } from '../style/colors'

const GlobalStyle = createGlobalStyle`
  ${reset}
  * {
    box-sizing: border-box;
  }
  body {
    font-family: 'Money Penny', sans-serif;
    background: ${PALE_GREEN};
    color: #222;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
  }
  a {
    text-decoration: none;
  }
  h1, h2, h3, h4, h5 {
    font-weight: normal;
    color: ${BLACK};
    font-family: 'Radiant Beauty', sans-serif;
  }
  h1 {
    font-size: 9em;
  }
  h2 {
    font-size: 7em;
  }
  h3 {
    font-size: 5em;
  }
  h4 {
    font-size: 3em;
  }
  h5 {
    font-size: 2em;
  }
`

const TemplateWrapper = ({ children }) => {
  return (
    <BreakpointsProvider>
      <GlobalStyle theme="purple" />
      <Helmet>
        <html lang="en" />
        <meta name="theme-color" content="#fff" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
      </Helmet>
      <div>{children}</div>
    </BreakpointsProvider>
  )
}

export default TemplateWrapper
