import React from 'react'

import { Wrapper, Content, Image } from './Hero.style';

export default function Hero({ image, position = 'center center', children, blur }) {
  return (
    <Wrapper>
      <Image imgStyle={{ objectPosition: position }} blur={blur} fluid={image.childImageSharp.fluid} />
      <Content>
        {children}
      </Content>
    </Wrapper>
    
  )
}
