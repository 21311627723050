import styled from 'styled-components';
import { Col } from 'react-flexbox-grid';

import { WHITE } from '../style/colors';
import { PADDING } from '.';

export const Heading = styled.h1`
  color: ${WHITE};
  text-align: center;
`;

export const Subheading = styled.h3`
  color: ${WHITE};
  text-align: center;
`;

export const Content = styled.div`
  h1, h2, h3, h4, h5 {
    margin-top: ${PADDING}px;
    &:first-of-type {
      margin-top: 0;
    }
  }
  p {
    margin-bottom: ${PADDING}px;
    
    &:last-of-type {
      margin: 0;
    }
  }
  ul {
    list-style: disc;
    margin-left: ${PADDING}px;
    margin-bottom: ${PADDING}px;
  }
`;

export const Paragraph = styled.p`
  margin-bottom: ${PADDING}px;
    
  &:last-of-type {
    margin: 0;
  }
`;

export const Box = styled.div`
  padding: ${PADDING}px;
  max-width: 600px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Benefits = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Benefit = styled(Col)`
  min-height: 40px;
`;

export const Testimonial = styled.span`
  line-height: 30px;
`;

export const Quote = styled.span`
  font-weight: bold;

`;

export const Author = styled.span`
  white-space: nowrap;
`;