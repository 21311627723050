import { graphql, useStaticQuery } from 'gatsby'

const useSiteMeta = () => {
  const result = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { template: { eq: "sitemeta" } }) {
          frontmatter {
            title
            description
            phone
            email
            address
            facebook
            instagram
            nav {
              links {
                label
                path
              }
            }
          }
        }
      }
    `
  )
  return result.markdownRemark.frontmatter
}

export default useSiteMeta