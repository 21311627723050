import styled from 'styled-components';
import Img from 'gatsby-image'

import { PADDING } from '../../style';
import * as colors from '../../style/colors';

const getColor = ({ background }) => background ? colors[background] : colors.ORANGE;

// const getAltColor = (props) => getColor(props) === colors.ORANGE ? colors.PALE_GREEN : colors.ORANGE;

// const MID_COLOUR = '#C0A170'

export const Wrapper = styled.div`
  background: ${getColor};
  padding: ${PADDING * 2}px ${PADDING}px;
  display: flex;
  position: relative;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: ${({ reverse }) => reverse
    ? 'row-reverse'
    : 'row'
  };
  align-items: center;
  flex-wrap: wrap-reverse;
  justify-content: center;
  h4 {
    margin-bottom: ${PADDING}px;
  }

  em {
    font-style: italic;
  }

  ol {
    list-style: decimal;
  }

  li {
    margin-bottom: ${PADDING}px;

    p {
      margin: 0;
    }
  }
`;

export const Text = styled.div`
  min-width: 300px;
  ${({ centered }) => centered ? '' : 'flex: 1'};
`;

export const Col = styled.div`
  min-width: 300px;
  flex: 1;
`;

export const Image = styled(Img)`
  width: 100%;
  height: 100%;
  margin: 12px;
  border-radius: 5px;
  box-shadow: ${({ shadow }) => shadow ? '0 0 10px #333' : 'none'}
`